import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header';
import AuthService from 'services/auth.service';

function Register() {
    const [state, setState] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: "",
        country: "",
        password: "",
        password_confirmation: ""
    });

    const [errors, setErrors] = useState("");
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();

        const payload = {
            "first_name": state.first_name,
            "middle_name": state.middle_name,
            "last_name": state.last_name,
            "phone_number": state.phone_number,
            "address": state.address,
            "country": state.country,
            "email": state.email,
            "password": state.password,
            "password_confirmation": state.password_confirmation
        }

        AuthService.register(payload).then(function (response) {
            localStorage.setItem('authentication_token', response.data.token);

            Swal.fire({
                title: 'Успешно',
                text: 'Успешна регистрация. Благодаря Ви, че се регистрирахте в приложението. Нека да преминем към профила Ви.',
                icon: 'success',
                confirmButtonText: 'Напред',
            }).then(function () {
                setTimeout(function () {
                    if (localStorage.getItem('authentication_token')) {
                        navigate('/profile');
                    }
                }, 500);
            });
        }).catch(function (error) {
            setErrors(error.response.data.errors);

            if (error) {
                Swal.fire({
                    title: 'Грешка',
                    text: 'Грешка в данните. Моля проверете данните и опитайте отново.',
                    icon: 'error',
                    confirmButtonText: 'ОК',
                });
            }
        });
    }

    return (
        <>
            <Header title="Регистрация" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://yoanazhilkova.com">
                            <LazyLoad height={100}>
                                <img className="img-fluid arrow-back" src={require('assets/img/icons/arrow_back.svg').default} width={20} alt="Arrow Back" />
                            </LazyLoad>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-5">
                    <div className="form-card">
                        <div className="col-md-6">
                            <LazyLoad height={100}>
                                <img className="img-fluid form-card__image" src={require('assets/img/forms/auth.png')} alt="Login" />
                                <img className="img-fluid form-card__image-mobile" src={require('assets/img/forms/auth-mobile.png')} alt="Register Mobile" />
                            </LazyLoad>
                        </div>
                        <div className="col-md-6">
                            <h1 className="form-card__title">Регистрация</h1>

                            <form className="form-card__form mt-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="text"
                                                id="first_name"
                                                placeholder="Име"
                                                value={state.first_name}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.first_name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="text"
                                                id="middle_name"
                                                placeholder="Презиме"
                                                value={state.middle_name}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.middle_name}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="text"
                                                id="last_name"
                                                placeholder="Фамилия"
                                                value={state.last_name}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.last_name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="email"
                                                id="email"
                                                placeholder="Имейл"
                                                value={state.email}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.email}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="tel"
                                                id="phone_number"
                                                placeholder="Телефонен номер"
                                                value={state.phone_number}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.phone_number}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="text"
                                                id="address"
                                                placeholder="Адрес"
                                                value={state.address}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.address}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="text"
                                                id="country"
                                                placeholder="Държава"
                                                value={state.country}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.country}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="password"
                                                id="password"
                                                placeholder="Парола"
                                                value={state.password}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.password}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input
                                                className="form-card__input"
                                                type="password"
                                                id="password_confirmation"
                                                placeholder="Потвърди парола"
                                                value={state.password_confirmation}
                                                onChange={handleChange}
                                            />
                                            {errors && (
                                                <span className="error-message">{errors.password_confirmation}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    </div>
                                </div>
                                <div className="form-group mt-5">
                                    <button className="form-card__button" type="button" onClick={handleSubmitClick}>Регистрация</button>

                                    <p className="form-card__paragraph mt-3">
                                        Вече имаш акаунт?

                                        <a className="form-card__login-button" href="/"> Влез в акаунт си тук</a>
                                    </p>
                                </div>
                            </form>
                        </div >
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;