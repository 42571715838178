import axios from 'api/api.js';

const getPackage = (payload) => {
    return axios.get('packages/' + payload);
};

const getAllPackages = () => {
    return axios.get('packages');
};

const purchasePackage = (payload) => {
    return axios.post('customers/purchase', payload);
};

const getAllUserPurchases = (payload) => {
    return axios.post('customers/list', payload);
}

const PackageService = {
    getPackage,
    getAllPackages,
    purchasePackage,
    getAllUserPurchases
};

export default PackageService;

