import LazyLoad from 'react-lazyload';

import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';

function FreeResources() {
    return (
        <>
            <Header title="Безплатни ресурси" />

            <Sidebar>
                <div className="free-resources-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center page-title">Безплатни ресурси</h2>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/img.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Здраве
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        Как да построим лесно хранителния си режим.
                                    </h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/video.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Фитнес
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        За различните упражнения, обхващащи един и същи мускул
                                    </h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/img.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Здраве
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        Как ледените вани могат да помогнат за кръвообращението ни
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/video.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Здраве
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        Как да построим лесно хранителния си режим.
                                    </h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/img.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Фитнес
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        За различните упражнения, обхващащи един и същи мускул
                                    </h3>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="free-resource-item">
                                    <div className="free-resource-item__image">
                                        <LazyLoad height={350}>
                                            <img src={require('assets/img/free-resources/video.png')} alt="free_resource" width={350} />
                                        </LazyLoad>
                                    </div>

                                    <h3 className="free-resource-item__category">
                                        Здраве
                                    </h3>

                                    <h3 className="free-resource-item__title">
                                        Как ледените вани могат да помогнат за кръвообращението ни
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default FreeResources;