import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';

function MyPlan() {
    return (
        <>
            <Header title="Моят план" />

            <Sidebar>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="text-center m-3">Страницата е в процес на разработване.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default MyPlan;