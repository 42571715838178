import LazyLoad from 'react-lazyload';

function Navbar({ isOpened }) {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <button className="navbar-btn" id="sidebarCollapse" type="button" onClick={isOpened}>
                        <img src={require('assets/img/icons/menu.svg').default} alt="Menu" />
                    </button>
                    <form className="d-flex">
                        <LazyLoad height={30}>
                            <img className="img-fluid navbar-action__icon" src={require('assets/img/icons/message.svg').default} width={40} alt="Message" />
                        </LazyLoad>
                        <LazyLoad height={30}>
                            <img className="img-fluid navbar-action__icon" src={require('assets/img/icons/notification.svg').default} width={30} alt="Menu" />
                        </LazyLoad>
                        <LazyLoad height={30}>
                            <img className="img-fluid " src={require('assets/img/users/avatar.svg').default} width={38} alt="Avatar" />
                        </LazyLoad>
                    </form>
                </div>
            </nav>
        </>
    );
}

export default Navbar;