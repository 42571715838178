import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header';
import AuthService from 'services/auth.service';

function ForgotPassword() {
    const [state, setState] = useState({
        email: "",
    });

    const [errors, setErrors] = useState("");

    const handleChange = (e) => {
        const { id, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();

        const payload = {
            "email": state.email,
        }

        AuthService.forgotPassword(payload).then(function () {
            setErrors([]);

            Swal.fire({
                title: 'Успешно',
                text: 'Моля, проверете вашия имейл.',
                icon: 'success',
                confirmButtonText: 'ОК',
            });
        }).catch(function (error) {
            setErrors(error.response.data.errors);

            Swal.fire({
                title: 'Грешка',
                text: 'Моля проверете данните и опитайте отново.',
                icon: 'error',
                confirmButtonText: 'ОК',
            });
        });
    }

    return (
        <>
            <Header title="Забравена парола" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <a href="/">
                            <LazyLoad height={100}>
                                <img className="img-fluid arrow-back" src={require('assets/img/icons/arrow_back.svg').default} width={20} alt="Arrow Back" />
                            </LazyLoad>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-5">
                    <div className="form-card">
                        <div className="col-md-6">
                            <LazyLoad height={100}>
                                <img className="img-fluid form-card__image" src={require('assets/img/forms/auth.png')} alt="Login" />
                                <img className="img-fluid form-card__image-mobile" src={require('assets/img/forms/auth-mobile.png')} alt="Login Mobile" />
                            </LazyLoad>
                        </div>
                        <div className="col-md-6">
                            <h1 className="form-card__title">Забравена парола</h1>

                            <form className="form-card__form mt-5">
                                <div className="form-group">
                                    <input
                                        className="form-card__input"
                                        type="email"
                                        id="
                                        email"
                                        placeholder="Имейл"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors && (
                                        <span className="error-message">{errors.email}</span>
                                    )}
                                </div>
                                <div className="form-group mt-5">
                                    <button className="form-card__button" type="button" onClick={handleSubmitClick}>Изпрати линк</button>
                                </div>
                            </form>
                        </div >
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;