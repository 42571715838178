import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import AuthService from 'services/auth.service';
import Navbar from 'components/Navbars/Navbar';

function Sidebar(props) {
    const [isOpened, setIsOpened] = useState(false);

    const toggleIsOpened = () => {
        setIsOpened(!isOpened);
    };

    const logoutUser = () => {
        AuthService.logout();
    };

    return (
        <>
            <div className="wrapper">
                <nav id="sidebar" className={isOpened ? "active" : ""}>
                    <div className="sidebar-header">
                        <a href="/profile">
                            <LazyLoad height={100}>
                                <img className="img-fluid" src={require('assets/img/sidebar/logo/logo.png')} width={135} alt="Sidebar" />
                            </LazyLoad>
                        </a>
                    </div>
                    <ul className="list-unstyled components mt-5">
                        <li>
                            <a href="/profile">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/profile.svg').default} width={20} alt="Profile" />
                                    Профил
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/my-plan">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/my_plan.svg').default} width={20} alt="My Plan" />
                                    Моят план <img className="img-fluid sidebar-icon arrow-sidebar" src={require('assets/img/sidebar/icons/arrow_right.svg').default} width={8} alt="Arrow Right" />
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/posing-calendar">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/calendar.svg').default} width={20} alt="Calendar" />
                                    Позинг календар
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/video">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/video.svg').default} width={20} alt="Video" />
                                    Видео
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/free-resources">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/resources.svg').default} width={20} alt="Resources" />
                                    Безплатни ресурси
                                </LazyLoad>
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a href="/packages">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/packages.svg').default} width={20} alt="Packages" />
                                    Пакети <img className="img-fluid sidebar-icon arrow-sidebar" src={require('assets/img/sidebar/icons/arrow_right.svg').default} width={8} alt="Arrow Right" />
                                </LazyLoad>
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="/posing-packages">
                                        Позинг
                                    </a>
                                </li>
                                <li>
                                    <a href="/fitness-packages">
                                        Фитнес
                                    </a>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <a href="/order-history">
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/history.svg').default} width={20} alt="History" />
                                    История на поръчките
                                </LazyLoad>
                            </a>
                        </li>
                        <li>
                            <a href="/" onClick={logoutUser}>
                                <LazyLoad height={20}>
                                    <img className="img-fluid sidebar-icon" src={require('assets/img/sidebar/icons/exit.svg').default} width={20} alt="Exit" />
                                    Изход
                                </LazyLoad>
                            </a>
                        </li>
                    </ul>
                </nav>

                <div id="content">
                    <Navbar isOpened={toggleIsOpened} />

                    {props.children}
                </div>
            </div >
        </>
    );
}

export default Sidebar;