import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import LazyLoad from 'react-lazyload';

import PackageService from 'services/package.service';
import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';
import { redirectToCheckout, redirectToOtherFitnessPackages } from 'pages/utils/utils';

function FitnessPackage() {
    const { slug } = useParams();

    const [fitnessPackage, setFitnessPackage] = useState([]);
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const getPackage = async () => {
            try {
                await PackageService.getPackage(slug).then(response => {
                    setFitnessPackage(response.data);
                });

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }

        const getAllPackages = async () => {
            try {
                await PackageService.getAllPackages().then(response => {
                    setPackages(response.data.packages);
                });
            } catch (error) {
                console.error(error.message);
            }
        }

        getPackage();
        getAllPackages();
    }, [slug]);

    const closeModal = () => setOpen(false);

    return (
        <>
            <Header title="Фитнес Пакети" />

            <Sidebar>
                <div className="fitness-packages-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center page-title">Фитнес Пакети</h2>

                                <hr />
                            </div>
                        </div>
                    </div>

                    {isLoading ?
                        <div className="text-center">
                            <div className="spinner-border spinner-loading text-center" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="package-section">
                                <div className="row justify-content-center mt-5">
                                    <div className="col-md-6 text-center">
                                        <img className="img-fluid package-card__thumbnail-desktop" src={fitnessPackage.picture} alt={fitnessPackage.name} width="450" />
                                        <img className="img-fluid package-card__thumbnail-mobile" src={fitnessPackage.picture} alt={fitnessPackage.name} width="450" />

                                        {
                                            fitnessPackage.is_bookable === 1
                                                ? <a className="fitness-package__button-booking" href={fitnessPackage.booking_link} target="_blank" rel="noreferrer">Запази своя ден и час</a>
                                                : null
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="fitness-package__title">{fitnessPackage.name}</h3>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="fitness-package__buy">
                                                    <button className="fitness-package__button-buy mt-3 mb-3" onClick={() => redirectToCheckout(fitnessPackage.slug)}>Купи сега</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="fitness-package__terms-of-use mb-3" onClick={() => setOpen(o => !o)}>
                                                    Условия за ползване
                                                </p>

                                                <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                                                    <h3 className="fitness-package__terms-of-use-title">{fitnessPackage.name}</h3>

                                                    <div className="fitness-package__terms-of-use-description" dangerouslySetInnerHTML={{ __html: fitnessPackage.terms_of_use }} />

                                                    <button className="popup-close" onClick={closeModal}>
                                                        &times;
                                                    </button>
                                                </Popup>
                                            </div>
                                        </div>
                                        <div className="fitness-package__description">
                                            <div dangerouslySetInnerHTML={{ __html: fitnessPackage.description }} />
                                        </div>

                                        <hr className="fitness-package__divider" />

                                        <div className="row px-3">
                                            {fitnessPackage.duration
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/duration.svg').default} width={30} alt="Duration" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__duration">
                                                            {fitnessPackage.duration}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="fitness-package__options">
                                                        <p className="fitness-package__duration-time">
                                                            {fitnessPackage.duration_time}
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {fitnessPackage.video
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/video.svg').default} width={30} alt="Video" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__video">
                                                            {fitnessPackage.video}
                                                        </span>
                                                    </div>
                                                    <br />
                                                </div>
                                                : null
                                            }
                                            {fitnessPackage.calendar
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/period.svg').default} width={30} alt="Period" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__calendar">
                                                            {fitnessPackage.calendar}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="fitness-package__options">
                                                        <p className="fitness-package__calendar-period">
                                                            Период
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {fitnessPackage.feedback
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/feedback.svg').default} width={30} alt="Feedback" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__feedback">
                                                            {fitnessPackage.feedback}
                                                        </span>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {fitnessPackage.location
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/location.svg').default} width={25} alt="Location" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__location">
                                                            {fitnessPackage.location}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="fitness-package__options">
                                                        <p className="fitness-package__location-place">
                                                            Titanium Fitness <br /> ж.к. Иван Вазов
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {fitnessPackage.online
                                                ?
                                                <div className="col-md-3 text-center">
                                                    <LazyLoad height={20}>
                                                        <img className="img-fluid" src={require('assets/img/packages/online.svg').default} width={25} alt="Online" />
                                                    </LazyLoad>
                                                    <div className="fitness-package__options">
                                                        <span className="fitness-package__online">
                                                            {fitnessPackage.online}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="fitness-package__options">
                                                        <p className="fitness-package__online-application">
                                                            Уеб приложение
                                                        </p>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="other-fitness-packages">
                                <div className="row justify-content-center mt-5">
                                    <div className="col-md-12 mt-5">
                                        <h3 className="other-fitness-packages__title mt-5">Други фитнес пакети</h3>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-1 d-flex align-items-center justify-content-center">
                                        <a role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <div className="carousel-nav-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <path d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-10">
                                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="row">
                                                        {packages.filter(item => item.slug !== fitnessPackage.slug && item.type === "fitness").slice(0, 3).map(filteredItem => (
                                                            <div style={{
                                                                backgroundImage: `url("${filteredItem.carousel_picture}")`,
                                                            }} className="col-6 col-md d-flex align-items-center justify-content-center"
                                                                onClick={() => redirectToOtherFitnessPackages(filteredItem.slug)}
                                                                key={filteredItem.id}>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="row">
                                                        {packages.filter(item => item.slug !== fitnessPackage.slug && item.type === "fitness").slice(3, 5).map(filteredItem => (
                                                            <div style={{
                                                                backgroundImage: `url("${filteredItem.carousel_picture}")`,
                                                            }} className="col-6 col-md d-flex align-items-center justify-content-center"
                                                                onClick={() => redirectToOtherFitnessPackages(filteredItem.slug)}
                                                                key={filteredItem.id}>
                                                            </div>
                                                        ))}
                                                        <div className="col-6 col-md d-flex align-items-center justify-content-center">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 d-flex align-items-center justify-content-center">
                                        <a role="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <div className="carousel-nav-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Sidebar>
        </>
    );
}

export default FitnessPackage;