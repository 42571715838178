import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Payment from 'components/Payments/Payment';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function StripeContainer() {
    return (
        <Elements stripe={stripePromise}>
            <Payment />
        </Elements>
    );
}

export default StripeContainer;