import React, { useState, useEffect } from 'react';
import PackageService from 'services/package.service';
import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';
import { redirectToCheckout } from 'pages/utils/utils';

function FitnessPackages() {
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        const getAllPackages = async () => {
            try {
                await PackageService.getAllPackages().then(response => {
                    setPackages(response.data.packages);
                });

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }

        getAllPackages();
    }, []);

    return (
        <>
            <Header title="Фитнес Пакети" />

            <Sidebar>
                <div className="dashboard-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center page-title">Фитнес пакети</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="fitness-packages-section">
                            <div className="row justify-content center">
                                {isLoading
                                    ?
                                    <div className="text-center">
                                        <div className="spinner-border spinner-loading text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : packages.filter(item => item.type === "fitness").map(filteredItem => (
                                        <div className="col-md-6 mt-5" key={filteredItem.id}>
                                            <div className="card flex-row package-card">
                                                <img className="img-fluid package-card__thumbnail-desktop" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />
                                                <img className="img-fluid package-card__thumbnail-mobile card-img-top" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />

                                                <div className="card-block px-2">
                                                    <h4 className="package-card__title-fitness mx-5">{filteredItem.name}</h4>
                                                    <p className="mx-5">
                                                        <span className="package-card__price-fitness">
                                                            {filteredItem.price}
                                                        </span>
                                                        <span className="package-card__period-fitness">
                                                            {filteredItem.period}
                                                        </span>
                                                    </p>
                                                    <button className="package-card__button-fitness mx-5" onClick={() => redirectToCheckout(filteredItem.slug)}>Купи сега</button>
                                                    <hr className="package-card__divider mx-5" />
                                                    <p className="package-card__summary mx-5">{filteredItem.summary}</p>
                                                    <a className="package-card__read-more mx-5" href={`fitness-packages/${filteredItem.slug}`}>Прочети още</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default FitnessPackages;