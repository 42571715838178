import { Helmet, HelmetProvider } from 'react-helmet-async';

function Header({ title }) {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{title} - {process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </HelmetProvider>
        </>
    );
}

export default Header;