import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';
import UserService from 'services/user.service';
import PackageService from 'services/package.service';

function OrderHistory() {
    const [user, setUser] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const payload = localStorage.getItem('authentication_token');

        const getCurrentUser = async () => {
            try {
                await UserService.getUser(payload).then(response => {
                    setUser(response.data);
                });
            } catch (error) {
                console.error(error.message);
            }
        }

        const getAllUserPurchases = async () => {
            setIsLoading(true);

            try {
                await PackageService.getAllUserPurchases(user.id).then(response => {
                    setSubscriptions(response.data.subscriptions);

                    setIsLoading(false);
                });
            } catch (error) {
                setIsLoading(false);
            }
        }

        getCurrentUser();
        getAllUserPurchases();
    }, [])

    return (
        <>
            <Header title="История на поръчките" />

            <Sidebar>
                <div className="orders-history-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center page-title">История на поръчките</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="orders-section">
                            <div className="row justify-content-center">
                                {isLoading
                                    ?
                                    <div className="text-center mt-5">
                                        <div className="spinner-border spinner-loading text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : subscriptions.map(subscription => (
                                        <div className="col-md-4 mt-5 text-center" key={subscription.id}>
                                            <div className="card">
                                                <div className="text-center">
                                                    <img className="img-fluid package-card__thumbnail-desktop" src={`http://localhost:8000/` + subscription.thumbnail} alt={subscription.name} width="250" />
                                                </div>

                                                <h4>{subscription.name}</h4>
                                                <p>Статус: {subscription.stripe_status === 'active' ? 'Активна' : 'Неактивна'} </p>
                                                <p>Количество: {subscription.quantity}</p>
                                                <p>Цена: {subscription.price}</p>

                                                <hr />

                                                <p>Тотал {subscription.price}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default OrderHistory;