import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthService from 'services/auth.service';

const AuthMiddleware = () => {
    const { pathname } = useLocation();
    const isLoggedIn = AuthService.isLoggedIn();

    return isLoggedIn ? (
        <Outlet />
    ) : (
        <Navigate to="/" state={{ from: pathname }} replace />
    );
};

export default AuthMiddleware;