import React, { useState, useEffect } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LazyLoad from 'react-lazyload';

import UserService from 'services/user.service';
import PackageService from 'services/package.service';

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#FCB3A5",
            color: "#000000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#FCB3A5" },
            "::placeholder": { color: "#FCB3A5" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        }
    }
}

function Payment() {
    const stripe = useStripe();
    const elements = useElements();
    const { slug } = useParams();

    const [user, setUser] = useState([]);
    const [packageInfo, setPackageInfo] = useState([]);

    useEffect(() => {
        const payload = localStorage.getItem('authentication_token');

        const getPackage = async () => {
            try {
                await PackageService.getPackage(slug).then(response => {
                    setPackageInfo(response.data);
                });
            } catch (error) {
                console.error(error.message);
            }
        }

        const getCurrentUser = async () => {
            try {
                await UserService.getUser(payload).then(response => {
                    setUser(response.data);
                });
            } catch (error) {
                console.error(error.message);
            }
        }

        getPackage();
        getCurrentUser();
    }, [slug]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (!error) {
            try {
                const { id } = paymentMethod;

                const payload = {
                    user_id: user.id,
                    price_id: packageInfo.prod_id,
                    id,
                };

                PackageService.purchasePackage(payload).then(response => {
                    if (response.data.success) {
                        console.log("Successful payment");
                    }
                });
            } catch (error) {
                console.log("Error", error);
            }
        } else {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        {packageInfo.type === "posing" ?
                            <a href={"/posing-packages/" + slug}>
                                <LazyLoad height={100}>
                                    <img className="img-fluid arrow-back" src={require('assets/img/icons/arrow_back.svg').default} width={20} alt="Arrow Back" />
                                </LazyLoad>
                            </a>
                            :
                            <a href={"/fitness-packages/" + slug}>
                                <LazyLoad height={100}>
                                    <img className="img-fluid arrow-back" src={require('assets/img/icons/arrow_back.svg').default} width={20} alt="Arrow Back" />
                                </LazyLoad>
                            </a>
                        }
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6 mt-5 text-center">
                        <div className="card flex-row package-card">
                            <img className="img-fluid package-card__thumbnail-desktop" src={packageInfo.thumbnail} alt={packageInfo.name} width="260" />
                            <img className="img-fluid package-card__thumbnail-mobile card-img-top" src={packageInfo.thumbnail} alt={packageInfo.name} width="260" />

                            <div className="card-block px-2">
                                <h4 className="package-card__title-posing mx-3">
                                    {packageInfo.name}
                                </h4>
                                <p className="mx-5">
                                    <span className="package-card__price-posing">
                                        {packageInfo.price}
                                    </span>
                                </p>
                                <hr className="package-card__divider mx-3" />
                                <p className="package-card__summary mx-3">
                                    {packageInfo.summary}
                                </p>
                                <a
                                    className="package-card__read-more mx-5"
                                    href={
                                        packageInfo.type === "posing"
                                            ? "/posing-packages/" + packageInfo.slug
                                            : "/fitness-packages/" + packageInfo.slug
                                    }
                                >
                                    Прочети още
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5 payment-card">
                        <h3 className="text-center">Плащане с карта</h3>

                        <form className="mt-5" onSubmit={handleSubmit}>
                            <fieldset>
                                <div className="mt-3">
                                    <CardElement options={CARD_OPTIONS} />
                                </div>
                            </fieldset>

                            <div className="mt-5 text-center">
                                <button className="btn mx-5 btn-info text-white package-card__button-posing">
                                    Плащане
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Payment;