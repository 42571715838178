export const redirectToCheckout = (slug) => {
    window.location.href = `/checkout/${slug}`;
}

export const redirectToOtherPosingPackages = (slug) => {
    window.location.href = `/posing-packages/${slug}`;
};

export const redirectToOtherFitnessPackages = (slug) => {
    window.location.href = `/fitness-packages/${slug}`;
};