import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';
import UserService from 'services/user.service';

function Profile() {
    const [user, setUser] = useState([]);

    const { first_name, last_name } = user;

    useEffect(() => {
        const payload = localStorage.getItem('authentication_token');

        const getCurrentUser = async () => {
            try {
                await UserService.getUser(payload).then(response => {
                    setUser(response.data);
                });
            } catch (error) {
                console.error(error.message);
            }
        }

        getCurrentUser();
    }, []);

    return (
        <>
            <Header title="Профил" />

            <Sidebar>
                <div className="dashboard-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <LazyLoad height={100}>
                                    <img className="img-fluid" src={require('assets/img/users/avatar.svg').default} width={125} alt="Avatar" />
                                </LazyLoad>
                            </div>
                            <div className="col-md-12 text-center mt-2">
                                <h3 className="profile-section__username">{first_name} {last_name}</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-5 text-center mt-2">
                                <div className="profile-section__actions">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4 mt-3">
                                            <LazyLoad height={50}>
                                                <img className="img-fluid" src={require('assets/img/icons/feedback.svg').default} width={44} alt="feedback" />
                                                <p className="profile-section__action-text">Качи видео</p>
                                            </LazyLoad>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <LazyLoad height={50}>
                                                <img className="img-fluid" src={require('assets/img/icons/profile.svg').default} width={30} alt="profile" />
                                                <p className="profile-section__action-text">Профил</p>
                                            </LazyLoad>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <LazyLoad height={50}>
                                                <img className="img-fluid" src={require('assets/img/icons/calendar.svg').default} width={40} alt="calendar" />
                                                <p className="profile-section__action-text">График</p>
                                            </LazyLoad>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-md-8 text-center">
                                <div className="motivational-box">
                                    <LazyLoad height={100}>
                                        <img className="img-fluid mt-2 mb-2" src={require('assets/img/icons/motivation.svg').default} width={125} alt="motivation" />
                                    </LazyLoad>
                                    <h3 className="motivational-box__heading">Girls, we can!</h3>
                                    <div>
                                        <p className="motivational-box__paragraph">
                                            ТИ си тук, защото искаш най-доброто за себе си, най-доброто за твоето тяло, защото то е всичко, което имаш ТИ, имам АЗ, имаме НИЕ!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph">
                                            ТИ четеш всичко това, защото те е грижа не само за теб, а и за всички твои близки, приятели, семейство. Уважението към собственото тяло е уважение и към всички останали!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph">
                                            Когато човек е здрав и се чувства добре в кожата си, той заразява с положителна енергия всички наоколо, способен е да помага, да вдъхновява и същевременно да усъвършенства себе си ментално и физически!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph">
                                            Респект за това, че искаш да действаш!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph">
                                            Не забравяй, че животът е един миг, изживей го качествено и пълноценно!
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph-thanks">
                                            Благодаря ти, че ти си тук с мен <img className="img-fluid mt-2 mb-2" src={require('assets/img/icons/heart.svg').default} width={20} alt="heart" /><br />
                                        </p>
                                    </div>
                                    <div>
                                        <p className="motivational-box__paragraph-author">
                                            Йоана Жилкова
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className="row justify-content-center mt-5">
                                <div className="col-md-12">
                                    <h3 className="motivational-section__title">Мотивационно видео</h3>
                                </div>
                                <div className="col-md-12">
                                    <hr className="divider" />
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="motivational-video">
                                        <iframe width="800" height="525" src="https://www.youtube.com/embed/fTVYrPGYVr0" title="YouTube video player" frameBorder="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default Profile;