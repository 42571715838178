import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import Sidebar from 'components/Sidebars/Sidebar';
import PackageService from 'services/package.service';

function Packages() {
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isShownPackages, setIsShownPackages] = useState(true);
    const [isShownPosingPackages, setIsShownPosingPackages] = useState(true);
    const [isShownFitnessPackages, setIsShownFitnessPackages] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const getAllPackages = async () => {
            try {
                await PackageService.getAllPackages().then(response => {
                    setPackages(response.data.packages);
                });

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }

        getAllPackages();
    }, []);

    const handlePosingClick = () => {
        setIsShownPackages(false);
        setIsShownFitnessPackages(true);
        setIsShownPosingPackages(!isShownPosingPackages);

        if (isShownPosingPackages === false) {
            setIsShownPackages(true);
        }
    };

    const handleFitnessClick = () => {
        setIsShownPackages(false);
        setIsShownPosingPackages(true);
        setIsShownFitnessPackages(!isShownFitnessPackages);

        if (isShownFitnessPackages === false) {
            setIsShownPackages(true);
        }
    };

    return (
        <>
            <Header title="Пакети" />

            <Sidebar>
                <div className="dashboard-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center page-title">Пакети</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center mt-5 mb-3">
                                <a className="posing-filter-button" onClick={handlePosingClick}>
                                    Позинг
                                </a>

                                <a className="fitness-filter-button" onClick={handleFitnessClick}>
                                    Фитнес
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="packages-section" style={{ display: isShownPackages ? 'block' : 'none' }}>
                            <div className="row justify-content-center">
                                {isLoading
                                    ?
                                    <div className="text-center mt-5">
                                        <div className="spinner-border spinner-loading text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : packages.map(item => (
                                        <div className="col-md-6 mt-5" key={item.id}>
                                            <div className="card flex-row package-card">
                                                <img className="img-fluid package-card__thumbnail-desktop" src={item.thumbnail} alt={item.name} width="260" />
                                                <img className="img-fluid package-card__thumbnail-mobile card-img-top" src={item.thumbnail} alt={item.name} width="260" />

                                                <div className="card-block px-2">
                                                    <h4 className={item.type === "posing"
                                                        ? "package-card__title-posing mx-5"
                                                        : "package-card__title-fitness mx-5"
                                                    }>{item.name}</h4>
                                                    <p className="mx-5">
                                                        <span className={item.type === "posing"
                                                            ? "package-card__price-posing"
                                                            : "package-card__price-fitness"}>
                                                            {item.price}
                                                        </span>
                                                        <span className="package-card__period-fitness">
                                                            {item.period}
                                                        </span>
                                                    </p>
                                                    <a
                                                        className={item.type === "posing"
                                                            ? "package-card__button-posing mx-5"
                                                            : "package-card__button-fitness mx-5"
                                                        }
                                                        href="#">Купи сега
                                                    </a>
                                                    <hr className="package-card__divider mx-5" />
                                                    <p className="package-card__summary mx-5">{item.summary}</p>
                                                    <a className="package-card__read-more mx-5" href={
                                                        item.type === "posing"
                                                            ? "/posing-packages/" + item.slug
                                                            : "/fitness-packages/" + item.slug
                                                    }>Прочети още</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                <div className="col-md-6">
                                </div>
                            </div>
                        </div>

                        <div className="posing-packages-section" style={{ display: isShownPosingPackages ? 'none' : 'block' }}>
                            <div className="row justify-content center">
                                {isLoading
                                    ?
                                    <div className="text-center mt-5">
                                        <div className="spinner-border spinner-loading text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : packages.filter(item => item.type === "posing").map(filteredItem => (
                                        <div className="col-md-6 mt-5" key={filteredItem.id}>
                                            <div className="card flex-row package-card">
                                                <img className="img-fluid package-card__thumbnail-desktop" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />
                                                <img className="img-fluid package-card__thumbnail-mobile card-img-top" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />

                                                <div className="card-block px-2">
                                                    <h4 className="package-card__title-posing mx-5">{filteredItem.name}</h4>
                                                    <p className="mx-5">
                                                        <span className="package-card__price-posing">
                                                            {filteredItem.price}
                                                        </span>
                                                    </p>
                                                    <a className="package-card__button-posing mx-5" href="#">Купи сега</a>
                                                    <hr className="package-card__divider mx-5" />
                                                    <p className="package-card__summary mx-5">{filteredItem.summary}</p>
                                                    <a className="package-card__read-more mx-5" href={`posing-packages/${filteredItem.slug}`}>Прочети още</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="fitness-packages-section" style={{ display: isShownFitnessPackages ? 'none' : 'block' }}>
                            <div className="row justify-content center">
                                {isLoading
                                    ?
                                    <div className="text-center mt-5">
                                        <div className="spinner-border spinner-loading text-center" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    : packages.filter(item => item.type === "fitness").map(filteredItem => (
                                        <div className="col-md-6 mt-5" key={filteredItem.id}>
                                            <div className="card flex-row package-card">
                                                <img className="img-fluid package-card__thumbnail-desktop" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />
                                                <img className="img-fluid package-card__thumbnail-mobile card-img-top" src={filteredItem.thumbnail} alt={filteredItem.name} width="260" />

                                                <div className="card-block px-2">
                                                    <h4 className="package-card__title-fitness mx-5">{filteredItem.name}</h4>
                                                    <p className="mx-5">
                                                        <span className="package-card__price-fitness">
                                                            {filteredItem.price}
                                                        </span>
                                                        <span className="package-card__period-fitness">
                                                            {filteredItem.period}
                                                        </span>
                                                    </p>
                                                    <a className="package-card__button-fitness mx-5" href="#">Купи сега</a>
                                                    <hr className="package-card__divider mx-5" />
                                                    <p className="package-card__summary mx-5">{filteredItem.summary}</p>
                                                    <a className="package-card__read-more mx-5" href={`fitness-packages/${filteredItem.slug}`}>Прочети още</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default Packages;