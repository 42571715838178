import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AuthMiddleware from 'config/middleware/auth.middleware';
import AuthService from 'services/auth.service';

import Layout from 'layouts/user/Layout';

import {
    Login,
    Register,
    ForgotPassword,
    ResetPassword
} from 'pages/authentication';

import {
    Profile,
    MyPlan,
    PosingCalendar,
    Video,
    FreeResources,
    OrderHistory
} from 'pages/common';

import {
    Packages,
    PosingPackages,
    PosingPackage,
    FitnessPackages,
    FitnessPackage
} from 'pages/packages';

import StripeContainer from 'pages/payments/StripeContainer';
import NotFound from 'pages/errors/NotFound';

function Router() {
    const isLoggedIn = AuthService.isLoggedIn();

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="*" element={<NotFound />} />

                <Route index element={isLoggedIn ? <Navigate to="/profile" /> : <Login />} />
                <Route path="/register" element={isLoggedIn ? <Navigate to="/profile" /> : <Register />} />
                <Route path="/forgot-password" element={isLoggedIn ? <Navigate to="/profile" /> : <ForgotPassword />} />
                <Route path="/reset-password/:token/" element={isLoggedIn ? <Navigate to="/profile" /> : <ResetPassword />} />

                <Route path="/*" element={<AuthMiddleware />}>
                    <Route path="profile" element={<Profile />} />
                    <Route path="my-plan" element={<MyPlan />} />
                    <Route path="posing-calendar" element={<PosingCalendar />} />
                    <Route path="video" element={<Video />} />
                    <Route path="free-resources" element={<FreeResources />} />

                    <Route path="packages" element={<Packages />} />
                    <Route path="posing-packages" element={<PosingPackages />} />
                    <Route path="posing-packages/:slug" element={<PosingPackage />} />
                    <Route path="fitness-packages" element={<FitnessPackages />} />
                    <Route path="fitness-packages/:slug" element={<FitnessPackage />} />

                    <Route path="order-history" element={<OrderHistory />} />

                    <Route path="checkout/:slug" element={<StripeContainer />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default Router;