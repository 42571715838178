import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2';
import Header from 'components/Headers/Header';
import AuthService from 'services/auth.service';

function Login() {
    const [state, setState] = useState({
        email: "",
        password: ""
    });

    const [errors, setErrors] = useState("");
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;

        setState(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();

        const payload = {
            "email": state.email,
            "password": state.password
        }

        AuthService.login(payload).then(function (response) {
            localStorage.setItem('authentication_token', response.data.token);

            if (localStorage.getItem('authentication_token')) {
                navigate('/profile');
            }
        }).catch(function (error) {
            setErrors(error.response.data.errors);

            Swal.fire({
                title: 'Грешка',
                text: 'Грешка в данните. Моля проверете данните и опитайте отново.',
                icon: 'error',
                confirmButtonText: 'ОК',
            });
        });
    }

    return (
        <>
            <Header title="Вход" />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://yoanazhilkova.com">
                            <LazyLoad height={100}>
                                <img className="img-fluid arrow-back" src={require('assets/img/icons/arrow_back.svg').default} width={20} alt="Arrow Back" />
                            </LazyLoad>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-5">
                    <div className="form-card">
                        <div className="col-md-6">
                            <LazyLoad height={100}>
                                <img className="img-fluid form-card__image" src={require('assets/img/forms/auth.png')} alt="Login" />
                                <img className="img-fluid form-card__image-mobile" src={require('assets/img/forms/auth-mobile.png')} alt="Login Mobile" />
                            </LazyLoad>
                        </div>
                        <div className="col-md-6">
                            <h1 className="form-card__title">Вход</h1>

                            <form className="form-card__form mt-5">
                                <div className="form-group">
                                    <input
                                        className="form-card__input"
                                        type="email"
                                        id="email"
                                        placeholder="Имейл"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                    {errors && (
                                        <span className="error-message">{errors.email}</span>
                                    )}
                                </div>
                                <div className="form-group mt-5">
                                    <input
                                        className="form-card__input"
                                        type="password"
                                        id="password"
                                        placeholder="Парола"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                    {errors && (
                                        <span className="error-message">{errors.password}</span>
                                    )}
                                </div>
                                <div className="form-group mt-5">
                                    <button className="form-card__button" type="button" onClick={handleSubmitClick}>Вход</button>

                                    <p className="form-card__paragraph mt-3">
                                        Все още нямаш акаунт?

                                        <a className="form-card__register-button" href="/register"> Регистрирай се тук</a>
                                    </p>

                                    <p className="form-card__paragraph mt-3">
                                        <a className="form-card__register-button" href="/forgot-password"> Забравена парола?</a>
                                    </p>
                                </div>
                            </form>
                        </div >
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;